import React from "react";

export const UserLogin = React.lazy(() => import("./UserLogin/index.page"));
export const UserHome = React.lazy(() => import("./UserHome/index.page"));
export const UserSignup = React.lazy(() => import("./UserSignup/index.page"));
export const UserForgot = React.lazy(() => import("./UserForgot/index.page"));
export const UserContact = React.lazy(() => import("./UserContact/index.page"));
export const UserAbout = React.lazy(() => import("./UserAbout/index.page"));
export const UserResources = React.lazy(() => import("./UserResources/index.page"));
export const UserReset = React.lazy(() => import("./UserReset/index.page"));
// export const UserBusiness = React.lazy(() => import("./UserBusiness/index.page"));
export const UserLoanApply = React.lazy(() => import("./UserLoanApply/index.page"));
export const UserFaq = React.lazy(() => import("./UserFaq/index.page"));
export const UserTerms = React.lazy(() => import("./UserTerms/index.page"));
export const UserPrivacy = React.lazy(() => import("./UserPrivacy/index.page"));
export const UserSupport= React.lazy(() => import("./UserSupport/index.page"));
export const UserProfile= React.lazy(() => import("./UserProfile/index.page"));
export const UserLines= React.lazy(() => import("./UserLines/index.page"));
export const UserTermLoan= React.lazy(() => import("./UserTermLoan/index.page"));
export const UserBlogDetails= React.lazy(() => import("./UserBlogDetails/index.page"));
export const UserLoanList= React.lazy(() => import("./UserLoanList/index.page"));
