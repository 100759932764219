import CryptoJS from "crypto-js";
import moment from "moment";
// import parse from "html-react-parser";
import { Link } from "react-router-dom";
import config from "../config";
import dayjs from "dayjs";

import Badge from "react-bootstrap/Badge";

let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone");
let relativeTime = require("dayjs/plugin/relativeTime");
let localeData = require("dayjs/plugin/localeData");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localeData);

const getItem = (path, label, key, icon, children) => {
  if (children) {
    return { label, key, icon, children, path };
  }
  return { label, key, icon, path };
};

export const getHeaderData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToHeader) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToHeader) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};
export const makeValidLink = (data) => {
  let link = `${
    data?.url?.search("http") < 0 ? `http://${data?.url}` : `${data?.url}`
  }`;
  return link;
};
export const phoneNumberField = (e) => {
  let ASCIICode = e.which ? e.which : e.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    e.preventDefault();
  }
};
export const getFooterLink = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToFooter) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToFooter) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const getSideBarMenu = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};

export const removeSessionStorageToken = () => {
  if (sessionStorage.getItem(`${config.NAME_KEY}:token`)) {
    sessionStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
};

export const setSessionStorageToken = (token) => {
  sessionStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const setSessionStorage = (keyName, formData) => {
  let stringData = JSON.stringify(formData);
  sessionStorage.setItem(
    `${config.NAME_KEY}:${keyName}`,
    CryptoJS.AES.encrypt(stringData, `${config.NAME_KEY}-${keyName}`).toString()
  );
};

export const getSessionStorage = (keyName) => {
  const cipherText = sessionStorage.getItem(`${config.NAME_KEY}:${keyName}`);
  if (cipherText) {
    const bytes = CryptoJS.AES.decrypt(
      cipherText,
      `${config.NAME_KEY}-${keyName}`
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return false;
};

export const removeSessionStorage = (keyName) => {
  if (sessionStorage.getItem(`${config.NAME_KEY}:${keyName}`)) {
    sessionStorage.setItem(`${config.NAME_KEY}:${keyName}`, "");
  }
};

export const removeLocalStorageToken = (navigate) => {
  if (localStorage.getItem(`${config.NAME_KEY}:token`)) {
    localStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
  if (navigate) {
    navigate("/");
  }
};

export const getSessionStorageToken = () => {
  const ciphertext = sessionStorage.getItem(`${config.NAME_KEY}:token`);
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const setLocalStorage = (keyName, formData) => {
  let stringData = JSON.stringify(formData);
  localStorage.setItem(
    `${config.NAME_KEY}:${keyName}`,
    CryptoJS.AES.encrypt(stringData, `${config.NAME_KEY}-${keyName}`).toString()
  );
};

export const getLocalStorage = (keyName) => {
  const cipherText = localStorage.getItem(`${config.NAME_KEY}:${keyName}`);
  if (cipherText) {
    const bytes = CryptoJS.AES.decrypt(
      cipherText,
      `${config.NAME_KEY}-${keyName}`
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return false;
};

export const removeLocalStorage = (keyName) => {
  if (localStorage.getItem(`${config.NAME_KEY}:${keyName}`)) {
    localStorage.setItem(`${config.NAME_KEY}:${keyName}`, "");
  }
};

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${config.NAME_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${config.NAME_KEY}:language`);
  if (language) {
    return ["en", "hi"].includes(language) ? language : config.DEFAULT_LANGUAGE;
  }
  return config.DEFAULT_LANGUAGE;
};

export const getCompleteUrl = (url) => {
  return url;
};

export function decodeQueryData(data) {
  return JSON.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace("?", "")}"}`
  );
}

export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function getSortType(data) {
  return data === "ASC" ? "asc" : "desc";
}

export function dateFormatter(params, format) {
  return (
    moment(params)
      // .tz(moment.tz.guess())
      .format(format ?? config.DATE_FORMAT)
  );
}
export const totalTimeDifference = (createdAt, updatedAt) => {
  let startTime = moment(createdAt);

  let endTime = moment(updatedAt);

  let difference = startTime.from(endTime);
  return difference;
};
export function dateNewFormatter(params, oldFormat, format) {
  return moment(params, oldFormat)
    .tz(moment.tz.guess())
    .format(format ?? config.DATE_FORMAT);
}

export function filterDateFormatter(param, format) {
  return moment(param).format(format);
}

export function momentDateFormatter(param, format) {
  return moment(param, format).tz(moment.tz.guess());
}

export function momentTimezoneFormatter(param) {
  return moment(param).tz(moment.tz.guess());
}

export function momentDateTimeTimezoneFormatter(param, timezone, format) {
  if (format) {
    return moment.tz(param, timezone).format(format);
  } else {
    return moment.tz(param, timezone);
  }
}

export function readMoreTextShow(data, showMoreText) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data.length < 50) {
    return <>{data}</>;
  }

  return (
    <p className="mb-0">
      {data.substring(0, 50)}...
      {showMoreText ? (
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            showMoreText({ data });
          }}
        >
          Read More
          {/* {t("common.readMore")} */}
        </Link>
      ) : (
        ""
      )}
    </p>
  );
}

export function PhoneNumber({ countryCode, contactNumber }) {
  if (countryCode && contactNumber) {
    return <>{`${countryCode}-${contactNumber}`}</>;
  }
  return <span className="center">-</span>;
}
export function PhoneNumberCountry({ countryCode, contactNumber }) {
  let code = "";
  if (countryCode !== null) {
    if (countryCode.toString().includes("+")) code = countryCode;
    else code = `+${countryCode}`;
  }
  if (countryCode !== null && contactNumber !== null) {
    return <>{`${code}-${contactNumber}`}</>;
  } else if (countryCode === null && contactNumber !== null) {
    return <>{`${contactNumber}`}</>;
  } else return <span className="center">-</span>;
}
export function otpRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}

export function phoneRegex() {
  let regex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return regex;
}

export function getSideBarData(arr) {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
}

// export const disabledFutureDate = (current) => {
//   return current > moment();
// };

// export const disabledPastDate = (current, startDate = new Date()) => {
//   return (
//     moment(
//       moment(startDate).add(0, "days").format("DD-MM-YYYY"),
//       "DD-MM-YYYY"
//     ) > moment(current.format("DD-MM-YYYY"), "DD-MM-YYYY")
//   );
// };
export const disabledPastDateCurrent = (current) => {
  return moment().add(0, "days") >= current;
};
export const disabledPastTime = (date, data = moment(new Date())) => {
  let hours = [];
  const currentDate = data;
  if (
    !(
      moment(date, "YYYY-MM-DD").format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
    )
  )
    date = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  if (currentDate.format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {
    [...new Array(60)].forEach((item, key) => {
      if (key < currentDate.format("HH")) {
        hours.push(key);
      }
    });
  }

  return {
    disabledHours: () => hours,
    disabledMinutes: (selectedHour) => {
      let disabled = [];
      if (
        currentDate.format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD") &&
        selectedHour === Number(currentDate.format("HH"))
      ) {
        [...new Array(60)].forEach((item, key) => {
          if (key < currentDate.format("mm")) {
            disabled.push(key);
          }
        });
      }
      return disabled;
    },
  };
};

export const disableStartDateFutureDays = (
  current,
  values,
  dateFormat,
  initialValues
) => {
  let date;
  const end = momentDateFormatter(values.endDate, dateFormat);
  if (values.endDate === "") {
    date = !current.isBefore(moment());
  } else if (initialValues?.startDate || values.startDate === "") {
    date = !(current.isBefore(moment()) && end.isAfter(current));
  } else {
    date = !(current.isBefore(moment()) && end.add(1, "day").isAfter(current));
  }

  return date;
};

export const disableEndDateFutureDays = (current, dateFormat, fromDate) => {
  let date;
  let start;
  if (fromDate === "") {
    start = momentDateFormatter(new Date(), dateFormat);
    // date = !start.isSameOrBefore(current);
  } else {
    start = momentDateFormatter(fromDate, dateFormat);
  }
  date = !(current.isSameOrAfter(start) && current.isSameOrBefore(moment()));
  return date;
};

export const getUniqueListBy = (arr, key1) => {
  return [...new Map(arr.map((item) => [item[key1], item])).values()];
};

// export const stringToHTML = (str) => {
//   return parse(str);
// };
export const getCharLeft = (char, maxChar) => {
  if (char && maxChar)
    return `${maxChar - char?.replaceAll(" ", "")?.length}/${maxChar}`;
  else return `${maxChar}/${maxChar}`;
};

export const dateValidationForDOB = (param) => {
  return moment().subtract(param, "days");
};

export const momentTimeFormatter = (param) => {
  return moment(param);
};

export const agoDateTime = (addHour, addType, format, date = "") => {
  let getCurrentTime = momentTimeFormatter(date || new Date());
  return getCurrentTime.add(addHour, addType).format(format);
};

export const beforeDateTime = (addHour, addType, format, date = "") => {
  let getCurrentTime = momentTimeFormatter(date || new Date());
  return getCurrentTime.subtract(addHour, addType).format(format);
};

export const getTimezoneList = () => {
  let list = moment.tz.names();
  return list;
};

export const baseUrlGenerator = (link) => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }${link || ""}`;
};

export const copyToClipboard = (data) => {
  window.navigator.clipboard.writeText(data);
};

export const stripAllTags = (data) => {
  return data?.replace(/(<([^>]+)>)/gi, "");
};

export const serialNumberFormatter = (page, sizePerPage, index) => {
  return (page - 1) * sizePerPage + index + 1;
};

export const getCompleteImageUrl = (data) => {
  return `${config.API_BASE_URL}/${data}`;
};

export const checkValidData = (data) => {
  return data || "-";
};

export const amountFormatter = (data) => {
  return data ? `₹${data.toFixed(2)}` : "-";
};

export const passengerCountFormatter = (data) => {
  let colName = {
    adult: "Adult",
    children: "Childern",
    senior: "Senior Citizen",
  };

  return data
    ? Object.keys(data)
        .map((i) => `${data?.[i]} ${colName?.[i]}`)
        .join(", ")
    : "-";
};

export const checkValidPhoneNumber = (data) => {
  return data ? `+91-${data}` : "-";
};

export const checkValidCount = (data, isPrice = false) => {
  let val = data || 0;
  return isPrice ? `₹${val}` : val;
};

export function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
}

export const statusFormatter = (data) => {
  let statusObj = {
    accepted: "Accepted",
    approved: "Approved",
    pending: "Pending",
    rejected: "Rejected",
    hold: "Hold",
    available: "Available",
    submitted: "Submitted",
    submit_request: "Submit Request",
    active: "Active",
  };

  let obj = {
    success: ["accepted", "approved", "available", "submitted", "active"],
    warning: ["pending", "hold", "submit_request"],
    danger: ["rejected"],
  };

  let key;
  Object.keys(obj).forEach((item) => {
    if (obj[item].includes(data)) {
      key = item;
    }
  });

  return (
    <>
      <Badge bg={key}>{statusObj[data]}</Badge>
      {/* <span className={`badge badge-${key}`}>
      {statusObj[data]}
    </span> */}
    </>
  );
};

export const fileFormatter = (urlString) => {
  let data = {};

  if (urlString) {
    // Extract filename (including extension)
    const filename = urlString.split("/").pop();

    // Extract name (assuming it's before the last dot)
    const name = filename.split(".")[0];

    // Extract extension
    const extension = filename.split(".").pop();

    data.name = name;
    data.extension = extension;
  }

  return data;
};

export const dayJsFormatter = (date, format) => {
  return dayjs(date, format);
};

export const dayjsDateFormatter = (date, format) => {
  return date ? dayjs(date).format(format) : "-";
};

export const disabledPastDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().subtract(1, "day").endOf("day");
};

export const disabledFutureDate = (current) => {
  // Can not select days before today and today
  return current && current > dayjs().endOf("day");
};

export const disabledDOBDate = (current) => {
  return current && current > dayjs().subtract(18, "years").endOf("day");
};

export const dayJSSubtractDate = (data, value) => {
  return dayjs().subtract(data, value);
};

export const getMonthList = () => {
  return dayjs.months();
};

export const getYearList = () => {
  let data = dayJSSubtractDate(100, "years").format("YYYY");
  let currentYear = dayjs().format("YYYY");
  let diff = currentYear - data;
  let arr = [...new Array(diff)].map((i, k) => {
    return { label: currentYear - k, value: currentYear - k };
  });
  return arr;
};
