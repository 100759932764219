import { baseRoutes } from "../../helpers/baseRoutes";

const userRouteMap = {
  USERLOGIN: { path: `${baseRoutes.userBaseRoutes}login` },
  PARTNERLOGIN: { path: `${baseRoutes.userBaseRoutes}partner-login` },
  USERHOME: { path: `${baseRoutes.userBaseRoutes}` },
  USERCONTACT: { path: `${baseRoutes.userBaseRoutes}contactus` },
  USERSIGNUP: { path: `${baseRoutes.userBaseRoutes}signup` },
  PARTNERSIGNUP: { path: `${baseRoutes.userBaseRoutes}partner-signup` },
  USERABOUT: { path: `${baseRoutes.userBaseRoutes}aboutus` },
  USERFORGOT: { path: `${baseRoutes.userBaseRoutes}forgot-password` },
  PARTNERFORGOT: {
    path: `${baseRoutes.userBaseRoutes}partner-forgot-password`,
  },
  USERRESOURCES: { path: `${baseRoutes.userBaseRoutes}resources` },
  USERRESET: { path: `${baseRoutes.userBaseRoutes}reset-password` },
  PARTNERRESET: {
    path: `${baseRoutes.userBaseRoutes}partner-reset-password`,
  },
  // USERBUSINESS: { path: `${baseRoutes.userBaseRoutes}business-loan` },
  USERLOAN: { path: `${baseRoutes.userBaseRoutes}loan-apply` },
  USERFAQ: { path: `${baseRoutes.userBaseRoutes}faq` },
  USERTERMS: { path: `${baseRoutes.userBaseRoutes}terms-of-use` },
  USERPRIVACY: { path: `${baseRoutes.userBaseRoutes}privacy-policy` },
  USERSUPPORT: { path: `${baseRoutes.userBaseRoutes}support` },
  USERPROFILE: { path: `${baseRoutes.userBaseRoutes}profile` },
  USERLINES: { path: `${baseRoutes.userBaseRoutes}lines-credit` },
  USERTERMSLOAN: { path: `${baseRoutes.userBaseRoutes}term-loan` },
  USERBLOGDETAILS: { path: `${baseRoutes.userBaseRoutes}blog-details` },
  USERLOANLLIST: { path: `${baseRoutes.userBaseRoutes}loan-list`},
};
export default userRouteMap;
