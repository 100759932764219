import { PartnerPrivateLayout } from "../../layouts";
import privateRoutes from "./private.route";

export const partnerRoutes = () => {
  return [
    {
      element: <PartnerPrivateLayout />,
      children: [...privateRoutes()],
    },
  ];
};
