import {
  PartnerDashboard,
  PartnerLoanDetails,
  PartnerLoanList,
} from "../../pages";
import partnerRouteMap from "./partnerRouteMap";

export default function route() {
  return [
    {
      path: partnerRouteMap.DASHBOARD.path,
      name: "Partner Dashboard",
      element: <PartnerDashboard />,
      private: true,
    },
    {
      path: partnerRouteMap.LOANS.path,
      name: "Partner Loan",
      element: <PartnerLoanList />,
      private: true,
    },
    {
      path: `${partnerRouteMap.LOANS_DETAIL.path}/:id`,
      name: "Partner Loan",
      element: <PartnerLoanDetails />,
      private: true,
    },
  ];
}
